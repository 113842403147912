<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-table"></i> Inventory Summary Report</b-card-title>
			<b-card-sub-title>Summary of all assets in supply chain (asset owner)/warehouse
				(distributor/manufacturer)</b-card-sub-title>
			<div fluid class="px-2 mt-4">
				<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

				<!-- Filter  -->
				<b-row class="my-2">
					<b-col sm="12" md="3" lg="3">
						<b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
							class="filter">
							FILTER OPTIONS
						</b-button>
					</b-col>
					<b-col sm="12">
						<!-- Collapsible Filter Options -->
						<b-collapse id="collapse-1" class="mt-2" visible>
							<b-card>
								<!-- Company -->
								<b-row no-gutters>
									<b-col lg="3" md="10" sm="12" class="mr-4">
										<b-form-group label="Accountable Company"
											description="Company that is accountable to the assets">
											<v-select name="Accountable Company" class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.companyItems"
												:reduce="(company) => company.value" v-model="filterBy.company">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="3" md="10" sm="12" class="mr-4">
										<b-form-group label="Current Company User"
											description="Company that is currently using the assets">
											<v-select name="Current Company User" class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.companyItems"
												:reduce="(company) => company.value" v-model="filterBy.currentCompany">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="3" md="10" sm="12" class="mr-4">
										<b-form-group label="Asset's Location"
											description="Location where the asset(s) are currently stored">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.storageLocationItems"
												:reduce="(loc) => loc.value" v-model="filterBy.storageLocation">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
								</b-row>

								<!-- Asset -->
								<b-row no-gutters>
									<b-col lg="3" md="5" sm="12" class="mr-4">
										<b-form-group label="Asset Type">
											<v-select class="style-chooser" label="text"
												placeholder=" - Please select - "
												:options="filterByOptions.assetTypeItems"
												:reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a asset type
													</em>
												</template>
											</v-select>
										</b-form-group>
									</b-col>
									<b-col lg="3" md="5" sm="12" class="mr-4">
										<b-form-group class="font-weight-normal" label="Condition"
											description="Selected asset type's conditions">
											<b-form-select v-model="filterBy.condition"
												:options="filterByOptions.conditionItems" />
										</b-form-group>
									</b-col>
									<b-col lg="3" md="5" sm="12" class="mr-4">
										<b-form-group class="font-weight-normal" label="Category"
											description="For CUSTOM asset type only">
											<b-form-select v-model="filterBy.category"
												:options="filterByOptions.categoryItems" />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="3" md="5" sm="12" class="mr-4">
										<b-form-group class="font-weight-normal" label="Asset Status">
											<b-form-select name="Status" v-model="filterBy.status"
												:options="filterByOptions.statusItems" v-validate="'required'" />
											<span v-show="errors.has('Status')" class="help-block">{{
												errors.first('Status')
											}}</span>
										</b-form-group>
									</b-col>
									<b-col lg="3" md="5" sm="12" class="mr-4">
										<b-form-group label="Asset Code">
											<b-form-input name="Asset Code" type="search" class="numFont"
												v-model="filterBy.assetCode"
												v-validate="{ regex: /^([a-zA-Z0-9\-])*$/ }" placeholder />
											<span v-show="errors.has('Asset Code')" class="help-block">{{
												errors.first('Asset Code')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col lg="3" md="5" sm="12" class="mr-4">
										<b-form-group label="Last Inventory Date From">
											<b-form-datepicker v-model="filterBy.dateFrom" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
									<b-col lg="3" md="5" sm="12" class="mr-4">
										<b-form-group label="Last Inventory Date To">
											<b-form-datepicker v-model="filterBy.dateTo" locale="en" reset-button
												label-reset-button="Clear" :date-format-options="{
													year: 'numeric',
													month: 'short',
													day: '2-digit',
													weekday: 'short',
												}" :date-disabled-fn="dateFromDisabled" />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row no-gutters>
									<b-col sm="12">
										<b-button class="mr-1" variant="success" @click="onFilter">
											Generate
										</b-button>
										<b-button class="mr-1" variant="primary" @click="resetFilters">
											Reset
										</b-button>
									</b-col>
								</b-row>
							</b-card>
						</b-collapse>
					</b-col>
				</b-row>

				<div v-if="items.length > 0">
					<div class="btn-table-options">
						<b-row>
							<b-col sm="6" md="3">
								<b-dropdown text=" Select Actions " variant="dark" slot="append">
									<b-dropdown-item>
										<json-excel :fetch="onExportClick" :fields="exportFields" type="xls"
											:name="fileName + '.xls'">Export Report
											in Excel</json-excel>
									</b-dropdown-item>
									<b-dropdown-item>
										<json-excel :fetch="onExportClick" :fields="exportFields" type="csv"
											:name="fileName + '.csv'">Export Report
											to CSV</json-excel>
									</b-dropdown-item>
								</b-dropdown>
							</b-col>
							<b-col sm="6" md="3" offset-md="6" class="my-1 text-md-right">
								<b-input-group prepend="Show" append="/ Page">
									<b-form-select :options="pageOptions" v-model="pagination.perPage" />
								</b-input-group>
							</b-col>
						</b-row>
					</div>
					<b-table show-empty striped hover :items="items" :fields="fields" :per-page="pagination.perPage"
						:filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
						:sort-direction="sortDirection" responsive>

						<template v-slot:cell(assetCode)="row">
							<span class="numFont">
								{{ row.item.assetCode }}
							</span>
						</template>

						<template v-slot:cell(locationDays)="row">
							<span class="numFont">
								{{ row.item.locationDays }}
							</span>
						</template>

						<template v-slot:cell(assetAge)="row">
							<span class="numFont">
								{{ row.item.assetAge }}
							</span>
						</template>

						<template v-slot:cell(serialNo)="row">
							<span class="numFont">
								{{ row.item.serialNo }}
							</span>
						</template>

						<template v-slot:cell(remainingShelfLife)="row">
							<span class="numFont">
								{{ row.item.remainingShelfLife }}
							</span>
						</template>

						<template v-slot:cell(unitCost)="row">
							<span class="numFont">
								{{ row.item.unitCost }}
							</span>
						</template>

						<template v-slot:cell(description)="row">
							<span class="truncate-text">{{ row.item.description }}</span>
						</template>

						<template v-slot:cell(prevInventorySessionId)="row">
							<span class="numFont">
								{{ row.item.prevInventorySessionId }}
							</span>
						</template>

						<template v-slot:cell(currInventorySessionId)="row">
							<span class="numFont">
								{{ row.item.currInventorySessionId }}
							</span>
						</template>

						<template v-slot:cell(status)="row">
							<span v-if="row.item.status == 'Inactive'">
								<b-badge variant="secondary">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'Stored'">
								<b-badge variant="primary">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'In-Transit'">
								<b-badge variant="success">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'In-Repair'">
								<b-badge variant="info">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'Lost'">
								<b-badge variant="danger">
									{{ row.item.status }}
								</b-badge>
							</span>
							<span v-if="row.item.status == 'BER'">
								<b-badge variant="dark">
									{{ row.item.status }}
								</b-badge>
							</span>
						</template>
					</b-table>
					<b-row>
						<b-col md="8" sm="12" class="my-1">
						</b-col>
						<b-col md="4" sm="12" class="my-1">
							<b-pagination align="right" @change="onPageChanged" :total-rows="pagination.total"
								:per-page="pagination.perPage" v-model="pagination.currentPage" class="my-0" limit="1"
								prev-text="Prev" next-text="Next" hide-goto-end-buttons />
						</b-col>
					</b-row>
				</div>
				<div v-else>
					<b-card> No Results Found. </b-card>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import reportApi from '@/api/reportApi';

// Others
import JsonExcel from 'vue-json-excel';
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'inventory-summary-report',
	components: { JsonExcel, Loading },
	data() {
		return {
			items: [],
			exportItems: [],
			fields: [],
			pagination: {
				currentPage: 1,
				perPage: 10,
				total: 0,
				startAt: {},
				endAt: '',
			},
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			defaultFilterBy: {
				company: { ...config.companyDefaultValue },
				currentCompany: { ...config.companyDefaultValue },
				storageLocation: { ...config.storageLocationDefaultValue },
				assetType: { ...config.assetTypeDefaultValue },
				status: 'Stored',
				condition: null,
				category: null,
				assetCode: '',
				dateFrom: null,
				dateTo: null,
			},
			filterBy: {
				company: { ...config.companyDefaultValue },
				currentCompany: { ...config.companyDefaultValue },
				storageLocation: { ...config.storageLocationDefaultValue },
				assetType: { ...config.assetTypeDefaultValue },
				status: 'Stored',
				condition: null,
				category: null,
				assetCode: '',
				dateFrom: null,
				dateTo: null,
			},
			prevFilterBy: null,

			filterByOptions: {
				companyItems: [],
				storageLocationItems: [],
				conditionItems: config.assetConditionStatus,
				categoryItems: [{ ...config.dropdownDefaultValue }],
				statusItems: [
					{
						value: null,
						text: ' - Please select - ',
					},
					{
						value: 'Stored',
						text: 'Stored',
					},
					{
						value: 'In-Transit',
						text: 'In-Transit',
					},
					{
						value: 'Lost',
						text: 'Lost',
					},
				],
				assetTypeItems: [],
			},

			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUser: this.$store.getters.loggedUser,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		tableColumns() {
			let fields = [
				{
					key: 'assetCode',
					sortable: true,
				},
				{
					key: 'assetType',
					sortable: true,
				},
				{
					key: 'status',
					sortable: false,
				},
				{
					key: 'currentUser',
					label: "Current Company User",
					sortable: false,
				},
				{
					key: 'branchCode',
					sortable: false,
				},
				{
					key: 'currentLocation',
					sortable: false,
				},
				{
					key: 'locationDays',
					label: 'Location Days',
					sortable: true,
				},
				{
					key: 'assetAge',
					label: 'Asset Age',
					sortable: true,
				},
				{
					key: 'activationDate',
					label: 'Activation Date',
					sortable: true,
				}
			];

			let permissions = this.loggedUserCompany.permissions;
			if (permissions && permissions.inventory) {
				fields.push({
					key: 'assetName',
					sortable: false,
				});

				fields.push({
					key: 'serialNo',
					sortable: false,
				});

				fields.push({
					key: 'purchaseDate',
					label: 'Purchase Date',
					sortable: true,
				});

				fields.push({
					key: 'remainingShelfLife',
					label: 'Remaining Shelf Life',
					sortable: false,
				});

				fields.push({
					key: 'expirationDate',
					label: 'Expiration Date',
					sortable: true,
				});

				fields.push({
					key: 'uom',
					label: 'UOM',
					sortable: false,
				});

				fields.push({
					key: 'unitCost',
					label: 'Unit Cost',
					sortable: false,
				});

				fields.push({
					key: 'condition',
					sortable: false,
				});

				fields.push({
					key: 'category',
					sortable: false,
				});

				fields.push({
					key: 'description',
					sortable: false,
				});

				fields.push({
					key: 'prevInventorySessionId',
					label: 'Previous Inventory Session ID',
					sortable: false,
				});

				fields.push({
					key: 'prevInventoryDate',
					label: 'Previous Inventory Date',
					sortable: true,
				});

				fields.push({
					key: 'prevInventoriedBy',
					label: 'Previous Inventoried By',
					sortable: false,
				});

				fields.push({
					key: 'currInventorySessionId',
					label: 'Current Inventory Session ID',
					sortable: false,
				});

				fields.push({
					key: 'currInventoryDate',
					label: 'Current Inventory Date',
					sortable: true,
				});

				fields.push({
					key: 'currInventoriedBy',
					label: 'Current Inventoried By',
					sortable: false,
				});
			}

			if (permissions && permissions.dispatch) {
				fields.push({
					key: 'receiverLocation',
					sortable: false,
				});

				fields.push({
					key: 'Truck Company',
					sortable: false,
				});

				fields.push({
					key: 'Truck Driver',
					sortable: false,
				});

				fields.push({
					key: 'Plate No/CR/OR',
					sortable: false,
				});

			}

			return fields;
		},

		/**
		 * Returns the set of data to be included in the export. For now this just
		 * returns the data as is.
		 *
		 * @returns {Array} the set of data to be included in the export.
		 */
		exportData() {
			return this.exportItems;
		},

		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			let fields = {
				'Asset Code': 'assetCode',
				'Asset Type': 'assetType',
				Status: 'status',
				'Current Company User': 'currentUser',
				'Branch Code': 'branchCode',
				'Current Location': 'currentLocation',
				'Location Days': 'locationDays',
				'Asset Age': 'assetAge',
				'Activation Date': 'activationDate',
			}

			let permissions = this.loggedUserCompany.permissions;
			if (permissions && permissions.inventory) {
				fields = Object.assign(fields, {
					'Asset Name': 'assetName',
					'Serial No': 'serialNo',
					'Purchase Date': 'purchaseDate',
					'Remaining Shelf Life': 'remainingShelfLife',
					'Expiration Date': 'expirationDate',
					'Unit of Measure': 'uom',
					'Unit Cost': 'unitCost',
					Condition: 'condition',
					Category: 'category',
					Description: 'description',
					'Previous Inventory Session ID': 'prevInventorySessionId',
					'Previous Inventory Date': 'prevInventoryDate',
					'Previous Inventoried By': 'prevInventoriedBy',
					'Current Inventory Session ID': 'currInventorySessionId',
					'Current Inventory Date': 'currInventoryDate',
					'Current Inventoried By': 'currInventoriedBy',
				});
			}
			if (permissions && permissions.dispatch) {
				fields = Object.assign(fields, {
					'Receiver Location': 'receiverLocation',
					'Truck Company': 'Truck Company',
					'Truck Driver': 'Truck Driver',
					'Plate No/CR/OR': 'Plate No/CR/OR',
				});
			}

			return fields;
		},

		fileName() {
			let filename = 'InventorySummary';

			if (this.filterBy.company.id) {
				filename += `_${this.filterBy.company.name}`;
			}

			if (this.filterBy.currentCompany.id) {
				filename += `_${this.filterBy.currentCompany.name}`;
			}

			if (this.filterBy.company.id && this.filterBy.storageLocation.id) {
				filename = `${this.filterBy.company.name}_${this.filterBy.storageLocation.name}`;
			}

			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return `${filename}_${DateUtil.getDateInMMDDYYYYFormat(currTimeStamp)}`;
		},
	},
	watch: {
		'pagination.perPage'(value) {
			this.resetPagination();
			this.retrieveData(1, value);
		},
		'pagination.currentPage'(newVal, oldVal) {
			if (newVal === 1) {
				this.resetPagination();
				this.retrieveData(1, this.pagination.perPage);
			} else {
				if (newVal > oldVal) {
					// Set every last item before getting into new page to handle back paging correctly
					if (this.items.length > 0) {
						this.pagination.startAt[newVal] = this.items[this.items.length - 1].assetCode;
					}

					this.sortDirection = 'asc';
					this.retrieveData(newVal, this.pagination.perPage, this.pagination.endAt);
				} else {
					this.sortDirection = 'desc';
					this.retrieveData(newVal, this.pagination.perPage, this.pagination.startAt[newVal]);
				}
			}
		},
		"filterBy.currentCompany"(newVal) {
			if (newVal !== config.companyDefaultValue) {
				let company = this.allCompaniesObj[newVal.id];
				let companyId = company.id;
				this.filterByOptions.storageLocationItems = DropDownItemsUtil.retrieveStorageLocationsByCompany(companyId, this.allStorageLocationsObj);
			} else {
				this.filterByOptions.storageLocationItems = DropDownItemsUtil.retrieveActiveStorageLocations(this.allStorageLocationsObj);
			}
		},
		"filterBy.assetType"(newVal) {
			if (newVal !== config.assetTypeDefaultValue) {
				let assetType = this.allAssetTypesObj[newVal.id];

				if (!_.isEmpty(assetType.conditions)) {
					this.filterByOptions.conditionItems = DropDownItemsUtil.retrieveAssetConditions(assetType.conditions);
				} else {
					this.filterByOptions.conditionItems = config.assetConditionStatus;
				}

				if (!_.isEmpty(assetType.categories)) {
					this.filterByOptions.categoryItems = DropDownItemsUtil.retrieveAssetCategories(assetType.categories);
				} else {
					this.filterByOptions.categoryItems = [{ ...config.dropdownDefaultValue }];
				}
			}
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// Filter Access
				if (this.$store.getters.isAccounting) {
					this.$router.push('/dashboard');
					this.$toaster.warning('You are not allowed to access this page.');
				}

				// show loading indicator
				this.isLoading = true;

				this.fields = this.tableColumns;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };

				if (!this.isSuperAdmin) {
					let connectedStorageLocationsObj = { ...this.$store.getters.connectedStorageLocations };
					this.allStorageLocationsObj = { ...this.allStorageLocationsObj, ...connectedStorageLocationsObj };
				}

				this.filterByOptions.companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.filterByOptions.storageLocationItems = DropDownItemsUtil.retrieveActiveStorageLocations(this.allStorageLocationsObj);
				this.filterByOptions.assetTypeItems = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj);

				// Set Default Filter Options
				this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
				await this.resetFilters();

			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
	methods: {
		async resetFilters() {
			this.items = [];
			this.filterBy = { ...this.defaultFilterBy };

			// reset validation
			this.$validator.reset();
			this.errors.clear();

			this.resetPagination()
			await this.retrieveData(1, this.pagination.perPage);
		},

		dateFromDisabled(_ymd, date) {
			return date > new Date();
		},
		isValidAssetCode(assetCode) {
			return _.isEmpty(assetCode) ||
				ValidationUtil.isValidAssetCode(this.allAssetTypesObj, assetCode);
		},
		async validateFilter() {
			let isValid = await this.$validator.validateAll();

			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				return isValid;
			}

			if (!this.filterBy.company.id && !this.filterBy.currentCompany.id) {
				this.$toaster.warning(`At least "Accountable Company" or "Current Company User" must have value selected.`);
				isValid = false;
			} else if (!this.isValidAssetCode(this.filterBy.assetCode)) {
				this.$toaster.warning(`Invalid Asset Code. "${this.filterBy.assetCode}" doesn't follow any of your asset tagging format.`);
				isValid = false;
			} else if ((_.isEmpty(this.filterBy.dateFrom) && !_.isEmpty(this.filterBy.dateTo))
				|| (!_.isEmpty(this.filterBy.dateFrom) && _.isEmpty(this.filterBy.dateTo))) {
				this.$toaster.warning('Invalid Date Range. Date From and Date To must both have value.');
				isValid = false;
			} else if (this.filterBy.dateFrom > this.filterBy.dateTo) {
				this.$toaster.warning('Invalid Date Range. Date From must be less than Date To.');
				isValid = false;
			} else if (DateUtil.getNoOfDays(this.filterBy.dateFrom, this.filterBy.dateTo) > 90) {
				this.$toaster.warning('Invalid Date Range. Data range is allowed up to 90 days difference.');
				isValid = false;
			}

			return isValid;
		},
		getReportParameters() {
			let filter = { ...this.filterBy };
			filter.companyId = this.loggedUserCompany.id;

			if (this.filterBy.dateFrom) {
				filter.fromTimestamp = DateUtil.startDateTimeStamp(
					new Date(this.filterBy.dateFrom)
				);
			}
			if (this.filterBy.dateTo) {
				filter.toTimestamp = DateUtil.endDateTimeStamp(
					new Date(this.filterBy.dateTo)
				);
			}

			return filter;
		},
		async onFilter() {
			let isValid = await this.validateFilter();
			if (!isValid) {
				return;
			}

			this.resetPagination();
			await this.retrieveData(1, this.pagination.perPage);
		},
		async retrieveData(page, perPage, startAt = '') {
			// Reset items
			this.items = [];
			// Show loader
			this.isLoading = true;

			try {
				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
				let reportParam = this.getReportParameters();
				const { data } = await reportApi.getInventorySummaryReport(
					page,
					perPage,
					startAt,
					reportParam,
					view,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.items = data.items;

					if (_.isEmpty(this.items)) {
						this.$toaster.warning('No Results found.');
					} else {
						this.pagination.endAt = this.items[this.items.length - 1].assetCode;
					}

					if (!data.hasMoreData) {
						this.pagination.total = data.perPage * (data.currentPage - 1) + this.items.length;
					} else {
						this.pagination.total = data.perPage * data.currentPage + 1;
					}


					// Mark the prevFilter
					this.prevFilter = { ...this.filterBy };
				} else {
					this.$toaster.error('Error loading data. Please reload the page again.');
				}
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		async onExportClick() {
			var exportedItems = [];
			await this.exportReport('', 20000, exportedItems);
			return exportedItems;
		},
		async exportReport(startAt, perPage, exportedItems) {
			this.isLoading = true;
			try {
				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
				let reportParam = this.getReportParameters();
				const { data } = await reportApi.getInventorySummaryReport(
					1,
					perPage,
					startAt,
					reportParam,
					view,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					exportedItems.push(...data.items);
					this.$toaster.success(`Exporting ${exportedItems.length} data...`);

					if (data.hasMoreData) {
						await this.exportReport(exportedItems[exportedItems.length - 1].assetCode, perPage, exportedItems);
					}
				} else {
					this.$toaster.error('Error loading data. Please reload the page again.');
				}
			} catch (_error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},
		resetPagination() {
			this.sortDirection = 'asc';
			this.pagination.startAt = {};
			this.pagination.endAt = '';
			this.pagination.currentPage = 1;
		},
		onPageChanged(currentPage) {
			this.pagination.currentPage = currentPage;
		},
	},
};
</script>